import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { getProductList } from '../../api';
import Card from '../../components/Card';
import Carousel from '../../components/Carousel';
import Loader from '../../components/Loader';
import type { TStoreFilter } from '../../constants/store';
import { STORE } from '../../constants/store';
import type { TExchangeRate, TProductListItem } from '../../types';
import { shuffle } from '../../utils/shuffle';

import css from './mainPage.module.scss';

export const MainPage = (): JSX.Element => {
  const { locale } = useRouter();
  const { data: exchangeRate } = useQuery<TExchangeRate>([
    STORE.EXCHANGE_RATE,
    locale,
  ]);
  const {
    data: productList,
    isLoading: productListLoading,
    // error: productListError,
  } = useQuery({
    queryKey: [STORE.PRODUCT_LIST, exchangeRate.conversionRate, locale],
    queryFn: getProductList,
  });
  const { data: filter } = useQuery<TStoreFilter>([STORE.FILTER]);
  const filteredCards: TProductListItem[] = useMemo(() => {
    if (productList) {
      const searchValue = filter ? filter.searchValue.toLowerCase() : '';
      const filteredList = productList.filter(item =>
        item.productName.toLowerCase().includes(searchValue),
      );

      return shuffle(filteredList);
    } else {
      return [];
    }
  }, [productList, filter]);

  return (
    <div className={css.wrapper}>
      {productListLoading && (
        <div className={css.loaderWrapper}>
          <Loader className={css.loader} />
        </div>
      )}
      <Carousel className={css.carouselWrapper} />
      <div className={css.cards}>
        {filteredCards.map((item: TProductListItem) => (
          <React.Fragment key={item.id}>
            <Card data={item} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
