export const getPrettyPrice = (
  price?: number | null,
  lacale?: string,
): string | null => {
  if (typeof price !== 'number') return price;

  const [a, b] = `${price}`.split('.');
  let n = 0;
  const prettyPrice = a
    .split('')
    .reverse()
    .map(j => {
      if (n === 2) {
        n = 0;

        return ` ${j}`;
      } else {
        n = n + 1;
      }

      return `${j}`;
    })
    .reverse()
    .join('')
    .concat(b ? `.${b}` : '');

  return lacale
    ? `${prettyPrice} ${lacale === '_en' ? 'AMD' : 'AMD'}`
    : prettyPrice;
};
