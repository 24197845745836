import LogoLoadingSVG from 'assets/logo-loading.svg';
import PictureLoadingSVG from 'assets/picture-loading.svg';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { ROUTES } from 'constants/routes';

import Button from 'components/Button';
import Img from 'components/Img';

import { getPrettyPrice } from 'utils';

import type { TProductListItem } from '../../types';

import css from './card.module.scss';

interface TProps {
  data: TProductListItem;
}

const emptyPicturesURLs = [
  '/assets/empty_1.png',
  '/assets/empty_2.png',
  '/assets/empty_3.png',
  '/empty_4.png',
];

export const Card = ({ data }: TProps): JSX.Element => {
  const {
    id,
    createDate,
    logoURL,
    pictureURL,
    companyName, // 'SkyEng',
    price, // 5104,
    productName,
    type, // 'услуги',
  } = data;
  const router = useRouter();
  const { locale } = router;
  const isNew = createDate === '2024-05-13T12:11:19.0';
  const pictureComponent = useMemo(() => {
    const emptyPictureNumber = emptyPicturesURLs[Math.floor(Math.random() * 4)];

    return pictureURL ? (
      <Img
        className={css.pictureImg}
        url={pictureURL}
        LoadingImg={<PictureLoadingSVG viewBox="0 0 260 260" />}
      />
    ) : (
      <img
        className={css.pictureImg}
        src={emptyPictureNumber}
        alt="empty-photo"
      />
    );
  }, [pictureURL]);

  return (
    <div
      className={css.wrapper}
      onClick={(): void => {
        router.push(`${ROUTES.PRODUCT}/${data.id}`);
      }}
    >
      <div className={css.picture}>
        {pictureComponent}
        {isNew && (
          <span className={css.newLabel}>
            {locale === '_en' ? 'new' : 'նորույթ'}
          </span>
        )}
        {logoURL && (
          <div className={css.logo}>
            <Img
              className={css.logoImg}
              url={logoURL}
              LoadingImg={<LogoLoadingSVG viewBox="0 0 35 35" />}
            />
          </div>
        )}
      </div>
      <div className={css.type}>{type}</div>
      <p className={css.description}>{productName}</p>
      <p
        className={css.text}
        onClick={(e): void => {
          e.stopPropagation();
          router.push(`${ROUTES.SELLER}/${data.sellerId}`);
        }}
      >
        {companyName}
      </p>
      <p className={css.price}>{getPrettyPrice(price, locale)}</p>
      <Button
        className={css.button}
        onClick={(e): void => {
          e.stopPropagation();

          router.push(
            `${ROUTES.BUY}/?orderId=${id}&productName=${productName}&companyName=${companyName}&rubSum=${data.costInRubles}&paySum=${data.costInDollars}&conversionRate=${data.exchangeRate}`,
          );
        }}
      >
        {locale === '_en' ? 'Buy' : 'գնել'}
      </Button>
    </div>
  );
};
