import cn from 'classnames';
import type { ReactNode } from 'react';
import { useState } from 'react';

import css from './img.module.scss';

interface TProps {
  url: string;
  LoadingImg: ReactNode;
  className?: string;
}

export function Img(props: TProps): JSX.Element {
  const [isLoaded, setIsLoaded] = useState(false);
  const { className, LoadingImg, url, ...otherProps } = props;

  return (
    <div className={cn(className, css.wrapper, isLoaded && css.showOpacity)}>
      {LoadingImg}
      <picture key={url}>
        <img
          className={css.mainIng}
          alt="img"
          src={url}
          onLoad={(): void => setIsLoaded(true)}
          loading="lazy"
          decoding="async"
          {...otherProps}
        />
      </picture>
    </div>
  );
}
