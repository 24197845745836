// https://rsuitejs.com/components/carousel/
import EnMainPagePictureSVG from 'assets/en-main-page-picture.svg';
import HyMainPagePictureSVG from 'assets/hy-main-page-picture.svg';
import cn from 'classnames';
import { useRouter } from 'next/router';
import type { FormEvent } from 'react';
import React, { useState } from 'react';
import type { CarouselProps } from 'rsuite';
import { Carousel as CarouselRrsuite } from 'rsuite';

import css from './carousel.module.scss';

interface IProps extends CarouselProps {
  className?: string;
  urls?: string[];
}

const DEFAULT_ACTIVE_INDEX = 0;

export function Carousel(props: IProps): JSX.Element {
  const { className, urls = [] } = props;
  const { locale } = useRouter();
  const [activeIndex, setActiveIndex] = useState(DEFAULT_ACTIVE_INDEX);

  return (
    <div className={cn(css.wrapper, className)}>
      <div
        className={css.arrowLeft}
        onClick={(): void => {
          const value = activeIndex === 0 ? urls.length - 1 : activeIndex - 1;

          setActiveIndex(value);
        }}
      >
        {'<'}
      </div>

      <CarouselRrsuite
        // name="mainCarousel"
        placement="bottom"
        shape="bar"
        className={css.carousel}
        onChange={(event: FormEvent): void => {
          const target = event.target as HTMLInputElement;

          setActiveIndex(+target.value);
        }}
        autoplay
        activeIndex={activeIndex}
        defaultActiveIndex={DEFAULT_ACTIVE_INDEX}
      >
        {locale === '_en' ? (
          <EnMainPagePictureSVG className={css.img} viewBox="0 0 1440 300" />
        ) : (
          <HyMainPagePictureSVG className={css.img} viewBox="0 0 1440 300" />
        )}
      </CarouselRrsuite>

      <div
        className={css.arrowRight}
        onClick={(): void => {
          const value = activeIndex === urls.length - 1 ? 0 : activeIndex + 1;

          setActiveIndex(value);
        }}
      >
        {'>'}
      </div>
    </div>
  );
}
